.button-container {
  text-align: center; /* Centers inline or inline-block elements */
  width: 100%;        /* Ensures the container takes full width of its parent */
}

.welcome-container {
  text-align: center; /* Centers inline or inline-block elements */
  width: 100%;        /* Ensures the container takes full width of its parent */
  font-size: 18px;  
  border: 0px solid black;
}


.body .html {
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.submit-button {
  width: 60%;
  margin: 6px auto; 
  background-color: #f7f7f7; /* White background */
  color: #333333;            /* Dark grey text */
  border: 1px solid #ccc; 
  border-radius: 10px; 
  padding: 20px 16px;         /* Top and bottom padding, larger left and right padding */             
  cursor: pointer;           /* Cursor indicates the element is clickable */
  font-size: 18px;           /* Slightly larger text for better readability */
  transition: all 0.3s;      /* Smooth transition for hover effects */
  text-align: center;        /* Center text inside the button */
}

.skip-button {
  width: 20%;
  margin: 6px auto; 
  background-color: #f7f7f7; /* White background */
  color: #333333;            /* Dark grey text */
  border: 1px solid #ccc; 
  border-radius: 10px; 
  padding: 20px 16px;         /* Top and bottom padding, larger left and right padding */             
  cursor: pointer;           /* Cursor indicates the element is clickable */
  font-size: 12px;           /* Slightly larger text for better readability */
  transition: all 0.3s;      /* Smooth transition for hover effects */
  text-align: center;        /* Center text inside the button */
}

.skip-button:hover {
  background-color: #f7ece1; /* White background */
  border: 1px solid #fcba91; 
}

.submit-button:hover {
  background-color: #e2f7e1; /* White background */
  border: 1px solid #42ef73; 
}

.back-button {
  width: 80%;
  margin: 6px auto; 
  background-color: transparent; /* White background */
  color: grey;            /* Dark grey text */
  border: 1px solid #ccc; 
  border-radius: 10px; 
  border-left: 1px solid #ccc;    /* Light grey border */
  padding: 20px 16px;         /* Top and bottom padding, larger left and right padding */             
  cursor: pointer;           /* Cursor indicates the element is clickable */
  font-size: 18px;           /* Slightly larger text for better readability */
  transition: all 0.3s;      /* Smooth transition for hover effects */
  text-align: left;        /* Center text inside the button */
}

.back-button:hover {
  background-color: #d0edfa; /* Light grey background on hover/focus */
  border-color: hsl(208, 85%, 63%);        /* Darker border color on hover/focus */
  outline: none;             /* Remove outline on focus for a clean look */
}

.button-menu {
  width: 80%;
  margin: 6px auto; 
  background-color: #f7f7f7; /* White background */
  color: #333333;            /* Dark grey text */
  border: 1px solid #ccc; 
  border-radius: 10px; 
  border-left: 6px solid #ccc;    /* Light grey border */
  padding: 20px 16px;         /* Top and bottom padding, larger left and right padding */             
  cursor: pointer;           /* Cursor indicates the element is clickable */
  font-size: 18px;           /* Slightly larger text for better readability */
  transition: all 0.3s;      /* Smooth transition for hover effects */
  text-align: left;        /* Center text inside the button */
}

.button-menu:hover, .button-menu:focus {
  background-color: #d0edfa; /* Light grey background on hover/focus */
  border-color: hsl(208, 85%, 63%);        /* Darker border color on hover/focus */
  outline: none;             /* Remove outline on focus for a clean look */
}

.button-menu:active {
  background-color: #e0e0e0; /* Even darker grey background when active */
  box-shadow: inset 0 2px 4px rgba(0,0,0,0.2); /* Inner shadow to give a pressed effect */
}


.collect-container button {
  margin-right: 5px;
  padding: 8px 16px;
  cursor: pointer;
}

.confirm-button {
  width: 80%;
  margin: 6px auto; 
  background-color: green; /* White background */
  color: white;            /* Dark grey text */
  border: 1px solid greenyellow; 
  border-radius: 10px; 
  border-left: 6px solid greenyellow;    /* Light grey border */
  padding: 20px 16px;         /* Top and bottom padding, larger left and right padding */             
  cursor: pointer;           /* Cursor indicates the element is clickable */
  font-size: 18px;           /* Slightly larger text for better readability */
  transition: all 0.3s;      /* Smooth transition for hover effects */
  text-align: left;        /* Center text inside the button */
}

.breadcrumbs {
  width: 60%;             /* Sets the width of the breadcrumbs section to 60% of the page width */
  margin: 40px auto;      /* Centers the box horizontally and gives some space above and below */
  padding: 20px;          /* Adds padding inside the box for spacing around the text */
  padding-bottom: 30px;
  background-color: #f0f0f0; /* Sets the background color to a light grey */
  border: 1px solid #ccc; /* Adds a thin grey border */
  border-radius: 10px;    /* Sets rounded corners */
  color: black;           /* Sets the text color to black */
  text-align: left;       /* Aligns the text to the left */
  font-size: 18px;        /* Sets the font size to 14px */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow for better visual separation */
}


.instructions {
  width: 60%;             /* Sets the width of the instructions section to 60% of the page width */
  margin: 20px auto;      /* Centers the box horizontally and gives some space above and below */
  padding: 20px;          /* Adds padding inside the box for spacing around the text */
  background-color: white;/* Sets the background color to white */
  border: 1px solid grey; /* Adds a thin grey border */
  border-radius: 10px;    /* Sets rounded corners */
  color: black;           /* Sets the text color to black */
  text-align: left;       /* Aligns the text to the left */
  font-size: 20px;        /* Sets the font size to 18px */
  font-family: 'Poppins', sans-serif;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow for better visual separation */
}

.back-icon {
  vertical-align: top;
  padding-left: 0px;
  color: grey;
}

.welcome-message {
  width: 100%;             /* Sets the width of the instructions section to 60% of the page width */
  margin: 20px auto;      /* Centers the box horizontally and gives some space above and below */
  padding: 20px;          /* Adds padding inside the box for spacing around the text */
  background-color: white;/* Sets the background color to white */
  border: 1px solid grey; /* Adds a thin grey border */
  border-radius: 10px;    /* Sets rounded corners */
  color: black;           /* Sets the text color to black */
  text-align: left;       /* Aligns the text to the left */
  font-size: 16px;        /* Sets the font size to 18px */
  box-shadow: 0 4px 8px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow for better visual separation */
}

.start-button {
  width: 40%;
  margin: 6px auto; 
  background-color: #f7f7f7; /* White background */
  color: #333333;            /* Dark grey text */
  border: 1px solid #ccc; 
  border-radius: 10px; 
  padding: 20px 16px;         /* Top and bottom padding, larger left and right padding */             
  cursor: pointer;           /* Cursor indicates the element is clickable */
  font-size: 18px;           /* Slightly larger text for better readability */
  transition: all 0.3s;      /* Smooth transition for hover effects */
  text-align: center;        /* Center text inside the button */
}

.start-button:hover {
  background-color: #d0edfa; /* Light grey background on hover/focus */
  border-color: hsl(208, 85%, 63%);        /* Darker border color on hover/focus */
  outline: none;             /* Remove outline on focus for a clean look */
}

/* Collect.css */
.collect-container {
  width: 100%;
  position: relative;
  min-height: 100vh; /* Ensure container can cover the viewport */
}

.powered-by-container {
  position: absolute;
  right: 20px; /* Distance from the right edge */
  bottom: 20px; /* Distance from the bottom edge */
  display: flex;
  align-items: center;
}

.powered-by-text {
  margin-right: 0px; /* Space between text and image */
  font-size: 0.8rem; /* Smaller font size for the text */
}

.powered-by-image {
  width: 100px; /* Set the image size */
  height: 35px; /* Set the image size */
}
