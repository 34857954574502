@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap');

/* Apply Poppins globally */
html, body {
  font-family: 'Poppins', sans-serif;
  background-color: #f2f2f2;
}

.App {
  text-align: center;
  font-family: 'Eudoxus Sans', sans-serif;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::placeholder {
  color: gray;
  opacity: 0.5; /* Firefox */
}

.node-input {
  width: 30%; /* Set width to 100% of the container */
  padding: 12px; /* Add padding for better spacing */
  margin: 5px;
  border: 1px solid #37b5fe; /* Add a border */
  border-radius: 10px; /* Add rounded corners */
  font-size: 16px; /* Set font size */
  outline: none; /* Remove default outline */
  transition: border-color 0.3s ease-in-out; /* Add smooth transition for border color */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow for better visual separation */
}

.node-input:focus {
  border-color: #0455bf; /* Change border color on focus */
}

.navbar {
  background-color: white; /* Change background color as needed */
  color: #fff; /* Change text color as needed */
  padding: 0px 0px; /* Adjust padding as needed */
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar-brand {
  font-size: 24px; /* Adjust font size as needed */
}

.navbar-menu {
  display: flex;
  align-items: center;
}

.navbar-end {
  margin-left: auto;
}

.button {
  background-color: #0455bf; /* Change button background color as needed */
  color: #fff; /* Change button text color as needed */
  border: 2px solid #0455bf;
  padding: 10px 20px; /* Adjust button padding as needed */
  cursor: pointer;
  font-size: 16px; /* Adjust button font size as needed */
  border-radius: 10px; /* Adjust border radius as needed */
  transition: background-color 0.3s ease; /* Add smooth transition */
  margin-bottom: 40px;
}

.blue-button {
  background-color: #0455bf; /* Change button background color as needed */
  color: #fff; /* Change button text color as needed */
  border: 2px solid #0455bf;
  padding: 10px 20px; /* Adjust button padding as needed */
  cursor: pointer;
  font-size: 16px; /* Adjust button font size as needed */
  border-radius: 10px; /* Adjust border radius as needed */
  transition: background-color 0.3s ease; /* Add smooth transition */
}



.secondary_button {
  background-color: #fff; /* Change button background color as needed */
  color: #0455bf; /* Change button text color as needed */
  border: 2px solid #0455bf;
  padding: 10px 20px; /* Adjust button padding as needed */
  cursor: pointer;
  font-size: 16px; /* Adjust button font size as needed */
  border-radius: 10px; /* Adjust border radius as needed */
  transition: background-color 0.3s ease; /* Add smooth transition */
}

.secondary_button:hover {
  background-color: #0455bf; /* Change button background color as needed */
  color: #fff; /* Change button text color as needed */
  border: 2px solid #0455bf;
  padding: 10px 20px; /* Adjust button padding as needed */
  cursor: pointer;
  font-size: 16px; /* Adjust button font size as needed */
  border-radius: 10px; /* Adjust border radius as needed */
  transition: background-color 0.3s ease; /* Add smooth transition */
}


.tertiary_button {
  width: 100%;
  background-color: #f2f2f2; /* Change button background color as needed */
  color: #0455bf; /* Change button text color as needed */
  border: 2px solid #f2f2f2;
  padding: 10px 20px; /* Adjust button padding as needed */
  cursor: pointer;
  font-size: 16px; /* Adjust button font size as needed */
  border-radius: 10px; /* Adjust border radius as needed */
  transition: background-color 0.3s ease; /* Add smooth transition */
  text-align: left;
}

.tertiary_button:hover {
  background-color: #f2f2f2; /* Change button background color as needed */
  color: #0455bf; /* Change button text color as needed */
  border: 2px solid #0455bf;
  padding: 10px 20px; /* Adjust button padding as needed */
  cursor: pointer;
  font-size: 16px; /* Adjust button font size as needed */
  border-radius: 10px; /* Adjust border radius as needed */
  transition: background-color 0.3s ease; /* Add smooth transition */
}



.button:hover {
  background-color: #0056b3; /* Change button background color on hover as needed */
}


.trash-icon {
  color:#0455bf; /* Change the color to whatever you need */
  vertical-align: top; /* Aligns the icon vertically with the text */
}

.plus-icon {
  color:#0455bf; /* Change the color to whatever you need */
  vertical-align: top; /* Aligns the icon vertically with the text */
}

.divider{
    width:10px;
    height:auto;
    display:inline-block;
}

.spacer{
    width:30px;
    height:auto;
    display:inline-block;
}

/* TaskManager styles */
.task-manager {
  margin-top: 20px;
  margin-left: 60px;
  margin-right: 60px;
}

.task {
  margin-bottom: 30px;
  border: 1px solid #ccc; /* Add a border */
  padding: 15px;
  border-radius: 15px;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow for better visual separation */
}

.task-description {
  display: flex;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
}

.task-description textarea {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  resize: vertical;
  font-family: 'Poppins', sans-serif;
}

.task-description select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 10px;
  font-family: 'Poppins', sans-serif;
}

.task-controls {
  display: flex;
  padding: 5px;
  align-items: center;
  font-size: 14px;
}

.task-controls label {
  margin-right: 20px;
}

.task-controls button {
  background-color: #ffffff;
  color: #fff;
  border: none;
  padding: 10px 10px;
  border-radius: 5px;
  cursor: pointer;
}

.task-controls button:hover {
  
}



.project-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px; /* Add spacing above the table */
}

.project-table th,
.project-table td {
  padding: 8px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  font-family: 'Poppins', sans-serif;
}

.project-table th {
  cursor: pointer;
}

.project-table th:hover {
  background-color: #f2f2f2;
}

.project-table th:first-child,
.project-table td:first-child {
  width: 40%;
}

.project-table .success-score {
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.project-table .success-score.green {
  background-color: green;
}

.project-table .success-score.orange {
  background-color: orange;
}

.project-table .success-score.red {
  background-color: red;
}

.success-score {
  width: 50px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.navbar {
  display: grid;
  grid-template-columns: auto 1fr; /* Two columns, auto for the logo, 1fr for the buttons */
  align-items: center;
  padding: 10px;
  background-color: #fff;
}

.logo {
  width: 140px; /* Adjust size as needed */
  height: auto;
}

.navbar-right {
  text-align: right; /* Align buttons to the right */
}

.nav-button {
  margin-left: 10px; /* Add some space between buttons */
  padding: 8px 12px;
  font-size: 14px;
  background-color: #fff;
  color: #000000;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.nav-button:hover {
  background-color: #0056b3;
  color: #fff;
}


.welcome-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #f8f8f8;
}

.splash {
  width: 40%;
  height: auto;
  display: inline-block; /* Set display property to inline-block */
}

.welcome-message {
  text-align: center;
  max-width: 600px;
}

h1 {
  font-size: 2.5rem;
  margin-bottom: 0px;
  font-family: 'Eudoxus Sans', sans-serif;
  color: #0056b3;
  padding-bottom: 0px;
}

h1-large {
  font-size: 4.5rem;
  margin-bottom: 0px;
  font-family: 'Eudoxus Sans', sans-serif;
  font-weight: bold;
  padding-bottom: 0px;
background: #121FCF;
background: linear-gradient(to top right, #121FCF 0%, #CF22C3 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
}

h2 {
  font-family: 'Eudoxus Sans', sans-serif;
}

h3 {
  margin-top: 0; /* Remove top margin */
  margin-bottom: 30px; /* Remove bottom margin */
  font-family: 'Eudoxus Sans', sans-serif;
}

h4 {
  font-family: 'Eudoxus Sans', sans-serif;
}

p {
  font-size: 1.2rem;
  margin-bottom: 15px;
}

.login-button {
  padding: 10px 20px;
  font-size: 1.2rem;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-button:hover {
  background-color: #0056b3;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner {
  animation: spin 1s linear infinite; /* Apply spin animation to make it rotate */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


/* CSS Styles */
.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
}

.modal-content {
  width: 30%;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
}

.modal-content .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #black; /* Adjust as needed */
}

.input-group {
  margin-bottom: 20px;
  margin-right: 20px;
}

.label {
  margin-bottom: 5px;
}

.input-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.button-container {
  text-align: right;
}

/* Make the .blue-button class more specific */
.modal-content .button-container .blue-button {
  background-color: #0455bf;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.modal-content .button-container .blue-button:hover {
  background-color: darkblue;
}

.info-icon {
  position: absolute;
  margin-left: 5px;
  display: inline-block;
  border-bottom: 0px dotted black; /* Optional styling */
  color: #ccc;
}

.info-icon .tooltip-text {
  visibility: hidden;
  width: 600px;
  background-color: white;
  color: black;
  text-align: left;
  border-radius: 10px;
  border: 0px solid #ccc;
  padding: 10px 10px;
  position: absolute;
  z-index: 1;
  bottom: -40px;
  left: 50%;
  margin-left: 20px;
  font-size: 15px;
  font-weight: normal;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow for better visual separation */
}

.info-icon:hover .tooltip-text {
  visibility: visible;
}

.vertical-spacer{
  height: 100px;
}

.vertical-spacer-small{
  height: 50px;
}

.user-icon {
  margin-left: 6px; /* Adds space between the icon and the text */
  vertical-align: top; /* Aligns the icon vertically with the text */
}

/* Custom Dropdown Styles */
.custom-select {
  width: 100%;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: white;
  appearance: none; /* Removes default system styling */
  font-family: 'Poppins', sans-serif; /* Assuming Poppins is already included in your project */
}

.custom-select:focus {
  border-color: #004BA8;
  outline: none;
}

.option-container {
  display: block;
  position: relative;
  white-space: pre-wrap; /* Ensure that whitespace in option text is respected */
}

.option-main-text {
  color: black;
}

.option-highlight {
  color: green;
  font-weight: bold;
}

.status-pill {
  display: inline-block;
  padding: 5px 10px;
  font-size: 0.8rem;
  border-radius: 15px; /* Rounded corners */
  color: white; /* Text color */
  text-align: center;
  min-width: 75px;
}

.status-pill.draft { background-color: #6c757d; } /* Grey for draft */
.status-pill.live { background-color: #28a745; } /* Green for live */
.status-pill.closed { background-color: #dc3545; } /* Red for closed */

.task-controls .button-task {
  font-size: 14px;
  padding: 10px 10px;
  color: black;
  border: 1px solid white;
  border-radius: 12px;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s ease;
}

.button-task {
  font-size: 14px;
  padding: 10px 10px;
  color: black;
  border: 1px solid white;
  border-radius: 12px;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s ease;
}

 .button-task:hover {
  font-size: 14px;
  padding: 10px 10px;
  border: 1px solid;
  border-color: #0455bf;
  color: #0455bf;
  border-radius: 12px;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s ease; 
}

.task-controls .button-task:hover {
  font-size: 14px;
  padding: 10px 10px;
  border: 1px solid;
  border-color: #0455bf;
  border-radius: 12px;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.3s ease;
}

.dropdown-menu {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-menu button {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
  border: none;
  background: none;
  width: 100%;
  cursor: pointer;
}

.dropdown-menu button:hover {
  background-color: #f1f1f1;
}

.success-location {
  display: flex;          /* This makes child elements line up horizontally */
  align-items: center;    /* This centers the items vertically */
  gap: 0px;               /* Adds space between the dropdown and the button */
}

.button-remove {
  padding: 10px 10px;       /* Smaller padding to make button smaller */
  margin-left: 0px;       /* Optional: add left margin to separate from dropdown */
  cursor: pointer;        /* Cursor pointer to indicate it's clickable */
  font-size: 0.8rem;      /* Smaller font size for the button */
  line-height: 1;         /* Adjust line height to fit the button size */
  background-color: white; /* Optional: add background color to make it stand out */
  color: #ccc;           /* White text color for better visibility */
  border: none;           /* Remove border */
  border-radius: 5px;     /* Optional: rounded corners for the button */
}

.button-remove:hover {
  padding: 10px 10px;       /* Smaller padding to make button smaller */
  margin-left: 0px;       /* Optional: add left margin to separate from dropdown */
  cursor: pointer;        /* Cursor pointer to indicate it's clickable */
  font-size: 0.8rem;      /* Smaller font size for the button */
  line-height: 1;         /* Adjust line height to fit the button size */
  background-color: #f44336; /* Optional: add background color to make it stand out */
  color: white;           /* White text color for better visibility */
  border: none;           /* Remove border */
  border-radius: 5px;     /* Optional: rounded corners for the button */
}

.fixed-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #f8f9fa; /* Light grey background, change as needed */
  border-top: 1px solid #dee2e6; /* Adds a subtle border for the bar */
  padding: 20px 0px; /* Padding around the content in the bar */
  display: flex;
  justify-content: flex-end; /* Aligns the button to the right */
  align-items: center;
  z-index: 1000; /* Ensures the bar is above other content */
}

.save-button {
  padding: 8px 16px; /* Sizing for the button */
  font-size: 16px; /* Button font size */
  color: white; /* Text color */
  margin-right: 40px;
  background-color: #007bff; /* Bootstrap primary color, change as needed */
  border: none;
  border-radius: 8px; /* Rounded corners for the button */
  cursor: pointer;
}

.success-locations-label {
  display: inline-flex;
  align-items: center;
}

.success-locations-label svg {
  margin-right: 5px; /* Add some space between the icon and text */
}

.save-button:hover {
  background-color: #0056b3; /* Darker blue on hover, adjust as needed */
}

.task-summary{
  border: 0px solid #0056b3;
  background-color: white;
  border-radius: 20px;
  padding: 20px 20px;
  margin-bottom: 20px;
}

.task-summary:has(.task-row:hover){
  background-color:#e3ecf7;
}

.task-row {
  border: 0px solid #0056b3;
  border-radius: 15px;
}

.task-row:hover {
  background-color:#e3ecf7;
}

