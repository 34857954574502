.graph-table {
  border: 0px solid grey;
  border-radius: 15px;
}

.direct-success-bar {
    background-color: #57f757; /* Change button background color as needed */
    vertical-align: middle;
    text-align: center;
    color: white;
  }

  .indirect-success-bar {
    background-color: #87ea87; /* Change button background color as needed */
    text-align: center;
    color: white;
  }

  .direct-fail-bar {
    background-color: rgb(255, 97, 97); /* Change button background color as needed */
    text-align: center;
    color: white;
  }

  .indirect-fail-bar {
    background-color: rgb(252, 158, 158); /* Change button background color as needed */
    text-align: center;
    color: white;
  }

  .skip-bar {
    background-color: grey; /* Change button background color as needed */
    text-align: center;
    color: white;
  }

  result-label {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Poppins', sans-serif;
    color: #0056b3;
    padding-bottom: 0px;
    padding-top: 0px;
    font-weight: bold;
  }
  

  result-stat {
    margin-top: 0px;
    margin-bottom: 0px;
    font-family: 'Poppins', sans-serif;
    color: #0056b3;
    padding-bottom: 0px;
    padding-top: 0px;
    font-weight: bold;
  }


  .performance-tag {
    display: inline-block;
    margin-left: 5px;
    padding: 2px 6px;
    border-radius: 12px;
    font-size: 0.8em;
    color: white;
  }

  .speeding-warning-tag {
    display: inline-block;
    margin-left: 5px;
    padding: 2px 6px;
    border-radius: 12px;
    font-size: 0.8em;
    background-color: rgb(244, 236, 236);
    color: red;
  }

  .task-summary-table {
    width: 100%;
    margin: 20px 0;
    border-radius: 20px;
    background-color: white;
    padding: 6px;
  }
  
  .task-summary-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .task-summary-table th, .task-summary-table td {
    padding: 8px;
  }
  
  .task-summary-table th {
    text-align: left;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  
  .task-summary-table ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .task-summary-table li {
    margin: 5px 0;
  }

  thead{
    text-align: left;
  }

li{
  padding: 6px;
}

table {
  border-collapse: separate;
  text-indent: initial;
  border-spacing: 0px;
}
  
  
  