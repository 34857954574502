.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .hero-section {
    text-align: center;
    padding: 30px 60px;
    border: 0px solid #0097a7;
    border-radius: 20px;
    margin-top: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: linear-gradient(148deg, rgba(245,245,248,1) 0%, rgba(253,253,253,1) 100%);
  }
  
  .hero-section h1 {
    font-size: 2.5rem;
    margin-bottom: 30px;
  }
  
  .hero-section p {
    font-size: 1.5rem;
    margin-bottom: 30px;
  }
  
  .cta-button {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1.5rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  
  .cta-button:hover {
    background-color: #0056b3;
  }
  
  .features-section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 0px;
    margin-top: 40px;
    border: 0px solid #0097a7;
    border-radius: 20px;
    padding: 30px 60px;
    background: rgb(245,245,248);
background: linear-gradient(148deg, rgb(252, 252, 255) 0%, rgb(242, 249, 251) 100%);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .feature {
    width: calc(50% - 20px);
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
  }
  
  .feature img {
    width: 100%;
    border-radius: 10px;
  }
  
  .feature h2 {
    font-size: 2.5rem;
    margin-bottom: 10px;
    background: #121FCF;
background: linear-gradient(to top right, #121FCF 0%, #CF22C3 100%);
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  }
  
  .feature p {
    font-size: 1rem;
  }
  
  .benefits-section {
    text-align: center;
    margin-bottom: 50px;
  }
  
  .benefits-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .testimonials-section {
    text-align: center;
  }
  
  .testimonial {
    margin-bottom: 30px;
  }
  
  .testimonial img {
    width: 100px;
    border-radius: 50%;
  }
  
  .testimonial p {
    font-style: italic;
  }
  
  footer {
    text-align: center;
    padding: 20px 0;
    border-top: 1px solid #ddd;
  }

  .pricing-section {
    margin-top: 0px;
    padding: 20px;
  }
  
  .pricing-intro {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .pricing-cards {
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }
  
  .pricing-card {
    width: calc(50%);
    padding: 40px;
    border-radius: 30px;
    border: 1px solid #00bcd4;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin-right: 20px;
    margin-left: 20px;
    background-color: #fff;
  }
  
  .pricing-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .pricing-title {
    font-size: 24px;
    font-weight: bold;
    font-family: 'Eudoxus Sans', sans-serif;
    background: #121FCF;
    background: linear-gradient(to top right, #121FCF 0%, #CF22C3 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

.pricing-title .pro{
    
  }
  
  .pricing-price {
    font-size: 24px;
    color: #00bcd4; /* Or any other color you prefer */
  }
  
  .pricing-details {
    margin-bottom: 15px;
  }
  
  .pricing-annual {
    font-size: 14px;
    color: #888;
  }
  
  .pricing-button {
    padding: 10px 20px;
    background-color: #00bcd4; /* Or any other color you prefer */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s ease;
  }
  
  .pricing-button:hover {
    background-color: #0097a7; /* Or any other color you prefer */
  }
  
  .pricing-description {
    font-size: 16px;
  }
  
  @media (max-width: 768px) {
    .pricing-card {
      width: 100%;
      margin-bottom: 20px;
    }
  }
  