/* TreeBuilder.css */
.saas-tree {
  font-family: 'Poppins', sans-serif;
  padding: 20px;
  margin-left: 40px;
}

.level-label {
  font-weight: italic;
  color:cornflowerblue;
  white-space: nowrap;
  display: inline;
  font-size: 12px;
}




.tree {
  display: flex;
  flex-direction: column; /* Change flex direction to column */
}

.tree-node {
  margin-bottom: 0px;
  margin-left: 15px; /* Set a fixed margin for indentation */ 
  border: 1px dashed transparent;
  padding: 5px;
}

.blank-node{
  width: 30%; /* Set width to 100% of the container */
  padding: 12px; /* Add padding for better spacing */
  margin: 5px;
  border-radius: 10px; /* Add rounded corners */
  font-size: 16px; /* Set font size */
  outline: none; /* Remove default outline */
  transition: border-color 0.3s ease-in-out; /* Add smooth transition for border color */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Optional: adds a subtle shadow for better visual separation */
  border: 2px solid red;
  background-color: #ffe6e6;
}

.tree-node:hover{
  background-color: aliceblue;
  background: transparent/9;
  border-radius: 15px;
  border: 1px dashed blue;
}

.tree-node:hover button{
  visibility: visible;
} 

.tree-node input {
  margin-right: 20px;
  font-size: 16px;
  background-color:white;
}

.tree-node input:hover {
  border: 1px solid;
}


.tree-node span {
  margin-right: 5px;
  font-size: 16px;
}


.tree-node button {
  font-size: 16px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  visibility: hidden;
}


.tree-node button:last-child {
  margin-left: 0px;
}

.root-node {
  margin-bottom: 5px; /* Adjust margin for root node */
}

.root-node input {
  margin-right: 10px;
  font-size: 16px;
}

.root-node input:hover {
  border: 1px solid;
}

.root-node span {
  margin-right: 5px;
  font-size: 16px;
}

.root-node button {
  font-size: 16px;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.modal button {
  font-size: 20px;
  margin-right: 10px;
  border: none;
  background-color: #007bff;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
}

.modal button:last-child {
  background-color: #dc3545;
}



