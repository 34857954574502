.settings {
    margin-left: 10%;
    margin-right: 10%;
    padding: 0px;
    background: #f0f0f0;
    border-radius: 10px;
}

.settings-section {
    border: 1px solid #ccc;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.live-banner{
    background-color:rgb(194, 255, 185);
    width: 60%;
    margin-left: 40px;
}

.banner-button{
    margin-left: 10px;
    background-color: #fefefe;
}

.banner-button:hover{
    margin-left: 10px;
    background-color: #d6ebff;
}


.settings-section h3 {
    margin-top: 0;
    margin-bottom: 10px;
    
}

.setting {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
}

/* Text area styling */
.settings-section textarea {
    width: 100%; /* Sets the width to take up the full container width */
    height: 150px;
    padding: 10px; /* Adds padding inside the text area */
    margin-top: 8px; /* Adds margin above the text area */
    margin-bottom: 8px; /* Adds margin below the text area */
    border: 1px solid #ccc; /* Adds a light grey border */
    border-radius: 8px; /* Adds rounded corners */
    box-sizing: border-box; /* Ensures padding and border are included in width calculation */
    resize: vertical; /* Allows vertical resizing only */
}

.settings-section {
    margin-bottom: 20px; /* Adds space between sections */
}

.switch input { 
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: .4s;
    border-radius: 34px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: .4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #4CAF50;
}

input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
    transform: translateX(26px);
}

.nested-settings {
    padding-left: 0px;
}

.input-group, .button-container {
    margin-bottom: 20px;
}

.textarea {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    resize: vertical;
}

.save-button {
    background-color: #0056b3;
    color: #fff; /* Change button text color as needed */
  border: 2px solid #0056b3;
  padding: 10px 20px; /* Adjust button padding as needed */
  cursor: pointer;
  font-size: 16px; /* Adjust button font size as needed */
  border-radius: 10px; /* Adjust border radius as needed */
  transition: background-color 0.3s ease; /* Add smooth transition */
  margin-right: 20px;
}

.finalise-button {
    background-color: #4CAF50;
    color: #fff; /* Change button text color as needed */
  border: 2px solid #4CAF50;
  padding: 10px 20px; /* Adjust button padding as needed */
  cursor: pointer;
  font-size: 16px; /* Adjust button font size as needed */
  border-radius: 10px; /* Adjust border radius as needed */
  transition: background-color 0.3s ease; /* Add smooth transition */
  margin-right: 20px;
}

.finalise-button:hover {
    background-color: #45a049;
}


.modal {
    position: fixed;
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
}

.modal-content {
    background-color: #fefefe;
    margin: 15% auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
    max-width: 500px;
}

.close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
}



.live-banner a {
    color: rgb(0, 0, 0);
    text-decoration: underline;
}
