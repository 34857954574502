.tabbed-layout {
  width: 100%;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  background-color: #f2f2f2;
  
}

.tabbed-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  border-bottom: 0px solid #ccc; /* Thick border along the bottom of tabs */
  border-top: 1px solid #ccc;
}

.tabbed-navigation button {
  background-color: transparent; /* Transparent background */
  border: none;
  border-bottom: 5px solid transparent; /* Fine border around the button */
  color: gray; /* Grey */
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin-right: 10px;
  margin-top: 30px;
  cursor: pointer;
  transition: border-bottom-color 0.3s ease; /* Smooth transition for border color */
}

.tabbed-navigation button:hover {
  border-bottom: 5px solid;
  border-bottom-color: #37b5fe; /* Highlight border color on hover */
}

.tabbed-navigation button.active {
  /*font-weight: bold;*/
  color: #0455bf; /* Green */
  border-bottom-color: #0455bf; /* Active tab border color */
}


.tabbed-navigation button:disabled {
  background-color: transparent; /* Transparent background */
  border: none;
  border-bottom: 0px solid transparent; /* Fine border around the button */
  cursor: not-allowed;
  color: lightgray; /* Green */
}

.tabbed-content {
  height: calc(100vh - 140px);
  padding: 20px;
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0 0 0px rgba(0, 0, 0, 0.1);
  border-bottom: 0px solid #ccc; /* Horizontal border below main content */
  margin-left: 30px;
  margin-right: 30px;
}
