.horizontal-histogram {
    width: 60%;
    margin-bottom: 20px;
  }
  
  .histogram-bar {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .label {
    width:25%;
    flex: 0 0 auto; /* Use auto to allow dynamic width setting */
    white-space: nowrap; /* Prevent the label text from wrapping */
    text-align: left;
    padding-right: 10px;
  }
  
  .bar {
    padding: 5px;
    color: white;
    border-radius: 4px;
    text-align: right;
  }
  